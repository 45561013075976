import { useEffect } from 'react';
import { useRecoilState } from 'recoil';

import { authState } from '../state';

const useIsLoggedIn = (
  isLoggedInCallback: () => void,
  isNotLoggedInCallback: () => void,
): void => {
  const [authStateValue, setAuthState] = useRecoilState(authState);

  useEffect(() => {
    if (typeof authStateValue?.token === 'string') {
      const callbackToCall =
        authStateValue.token.length > 0
          ? isLoggedInCallback
          : isNotLoggedInCallback;

      callbackToCall();

      return;
    }

    const token = sessionStorage.getItem('token');
    const headers = new Headers({
      Authorization: `Bearer ${token ?? ''}`,
    });

    (async () => {
      try {
        const response: Response = await fetch('/api/loggedin', {
          headers,
          method: 'GET',
        });

        const responseBody: ApiResponse<{ isLoggedIn: boolean }> =
          await response.json();

        if ('isLoggedIn' in responseBody) {
          const { isLoggedIn } = responseBody;

          if (isLoggedIn) {
            // API verified the token, so it’s not `null`.
            setAuthState({ token: token as string });
            isLoggedInCallback();
          } else {
            sessionStorage.setItem('token', '');
            setAuthState({ token: '' });
            isNotLoggedInCallback();
          }
        }
      } catch (error) {
        // TODO: error handling.
      }
    })();
  }, [authStateValue]);
};

export default useIsLoggedIn;
