import React, { type MouseEventHandler } from 'react';
import { useRecoilState } from 'recoil';

import GitHubLogo from '../../assets/svg/github-mark.svg';

import { authState } from '../../state';

function Footer(): JSX.Element {
  const [, setAuthState] = useRecoilState(authState);

  const handleClickSignOut: MouseEventHandler = () => {
    sessionStorage.setItem('token', '');
    setAuthState({ token: '' });
  };

  return (
    <footer className="max-lg:flex max-lg:items-center max-lg:justify-between pb-6 pl-2">
      <button
        className="lg:py-6 underline"
        onClick={handleClickSignOut}
        type="button"
      >
        Sign out
      </button>
      <div className="flex flex-col items-end lg:bottom-0 lg:fixed lg:right-0 lg:pb-6 lg:pr-6">
        <div>&copy; 2023 James Yen.</div>
        <div>All rights reserved.</div>
        <a
          className="flex items-center underline"
          href="https://github.com/jameshyen"
          rel="noreferrer noopener"
          target="_blank"
        >
          <img alt="GitHub logo" className="h-6 mr-1" src={GitHubLogo} />
          @jameshyen
        </a>
      </div>
    </footer>
  );
}

export default Footer;
