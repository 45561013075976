import React, { type FormEventHandler } from 'react';
import { useRecoilState } from 'recoil';

import { editorState } from '../../state';

function Input(): JSX.Element {
  const [editorStateValue, setEditorState] = useRecoilState(editorState);

  if (editorStateValue === null || editorStateValue.items === null) {
    return (
      <div aria-busy="true" className="animate-pulse mb-4">
        <div className=" bg-slate-200 h-7 rounded-full" />
      </div>
    );
  }

  const { setItems, setTextboxValue, textboxValue } = editorStateValue;

  const handleInputInput: FormEventHandler = (event) => {
    // TODO: hm...
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    setTextboxValue((event as any).target.value);
  };

  const handleSubmitForm: FormEventHandler = (event) => {
    event.preventDefault();

    setEditorState((currentEditorState) => {
      if (currentEditorState !== null) {
        return {
          ...currentEditorState,
          dirty: true,
        };
      }

      return currentEditorState;
    });

    setItems((currentItems: Nullable<Item[]>) => [
      // `id` is autogenerated by the database, so we don’t need to provide it.
      // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
      { content: textboxValue, isDeleted: false } as Item,
      ...(currentItems ?? []),
    ]);

    setTextboxValue('');
  };

  return (
    <form className="flex" onSubmit={handleSubmitForm} title="Add a new item">
      <input
        autoComplete="off"
        className="grow italic p-2 text-3xl"
        onInput={handleInputInput}
        placeholder="What’s on your mind?"
        required
        type="text"
        value={textboxValue}
      />
    </form>
  );
}

export default Input;
