import React, { type MouseEventHandler, useState } from 'react';
import { useRecoilState } from 'recoil';

import { editorState } from '../../state';

// eslint-disable-next-line @typescript-eslint/no-var-requires
const classnames = require('classnames');

function TodoList(): JSX.Element {
  const [editorStateValue, setEditorState] = useRecoilState(editorState);

  const [itemIdToExit, setItemIdToExit] = useState<Nullable<number>>(null);

  if (editorStateValue === null || editorStateValue.items === null) {
    return (
      <div aria-busy="true" className="animate-pulse flex flex-col">
        <div className="flex items-center mb-4">
          <div className=" bg-slate-200 h-9 mr-2 rounded-full w-9" />
          <div className=" bg-slate-200 grow h-7 rounded-full" />
        </div>
        <div className="flex items-center mb-4">
          <div className=" bg-slate-200 h-9 mr-2 rounded-full w-9" />
          <div className=" bg-slate-200 grow h-7 rounded-full" />
        </div>
        <div className="flex items-center mb-4">
          <div className=" bg-slate-200 h-9 mr-2 rounded-full w-9" />
          <div className=" bg-slate-200 grow h-7 rounded-full" />
        </div>
      </div>
    );
  }

  const { items, setItems } = editorStateValue;

  const todoItems = items.filter(({ isDeleted }) => !isDeleted);

  if (todoItems.length === 0) {
    return <h2 className="p-2 pr-0 text-3xl">✅ You have no todo items.</h2>;
  }

  const handleClickBumpButton =
    (id: number): MouseEventHandler =>
    () => {
      setItemIdToExit(id);

      setTimeout(
        () => {
          setEditorState((currentEditorState) => {
            if (currentEditorState !== null) {
              return {
                ...currentEditorState,
                dirty: true,
              };
            }

            return currentEditorState;
          });

          setItemIdToExit(null);

          setItems((currentItems: Nullable<Item[]>) => {
            // There is at least one item if an item is being bumped.
            const nextItems = [...(currentItems as Item[])];

            const itemToRemoveIndex = nextItems.findIndex(
              (item) => String(id) === String(item.id),
            );

            const [removedItem] = nextItems.splice(itemToRemoveIndex, 1);

            return [removedItem, ...nextItems];
          });
        },
        // TODO: really don’t like this...
        500,
      );
    };

  const handleClickDeleteButton =
    (id: number): MouseEventHandler =>
    () => {
      setItemIdToExit(id);

      setTimeout(() => {
        setEditorState((currentEditorState) => {
          if (currentEditorState !== null) {
            return {
              ...currentEditorState,
              dirty: true,
            };
          }

          return currentEditorState;
        });

        setItemIdToExit(null);

        setItems((currentItems: Nullable<Item[]>) => {
          // There is at least one item if an item is being deleted.
          const nextItems = [...(currentItems as Item[])];

          const itemToDeleteIndex = nextItems.findIndex(
            (item) => String(id) === String(item.id),
          );

          nextItems[itemToDeleteIndex].isDeleted = true;

          return nextItems;
        });
      }, 500);
    };

  return (
    <>
      <h2 className="flex font-medium items-center p-2 pr-0 text-4xl">
        Todo
        <hr className="grow ml-4" />
      </h2>
      <ol aria-live="polite">
        {/* TODO: error state. */}
        {todoItems.map((item, index) => (
          <li
            className={classnames('flex items-center justify-between', {
              animate__animated: index === 0 || item.id === itemIdToExit,
              animate__bounceInLeft: index === 0,
              animate__bounceOutLeft: item.id === itemIdToExit,
            })}
            key={btoa(String(item.id))}
          >
            <button
              className="cursor-pointer p-2 pr-0 text-3xl text-left"
              onClick={handleClickBumpButton(item.id)}
              title="Bump item to top"
              type="button"
            >
              {item.content}
            </button>
            <div className="whitespace-nowrap">
              <button
                className="cursor-pointer text-lg underline"
                onClick={handleClickDeleteButton(item.id)}
                type="button"
              >
                Delete
              </button>
              .
            </div>
          </li>
        ))}
      </ol>
    </>
  );
}

export default TodoList;
