import React from 'react';

function Editor(): JSX.Element {
  return (
    <header className="pt-6">
      <div className="flex justify-between max-md:flex-col">
        <h1 className="font-medium text-6xl">Streamnote</h1>
        <aside className="text-lg max-md:mt-2 md:self-end">
          No editing. No organizing. No fuss.
        </aside>
      </div>
      <hr className="max-md:mb-6 max-md:mt-4 md:my-6" />
    </header>
  );
}

export default Editor;
