import React, {
  type FormEvent,
  type FormEventHandler,
  type MouseEventHandler,
  useState,
} from 'react';
import { useNavigate } from 'react-router-dom';
import { useRecoilState } from 'recoil';

import { useIsLoggedIn } from '../../hooks';
import { authState } from '../../state';

// eslint-disable-next-line @typescript-eslint/no-var-requires
const classnames = require('classnames');

function SignIn(): JSX.Element {
  const navigate = useNavigate();

  const [, setAuthState] = useRecoilState(authState);

  const [error, setError] = useState<null | string>(null);
  const [email, setEmail] = useState('');
  const [isSigningUp, setIsSigningUp] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [password, setPassword] = useState('');

  useIsLoggedIn(
    (): void => {
      navigate('/');
    },
    (): void => {},
  );

  const handleClickSignIn: MouseEventHandler = () => {
    setError(null);
    setIsSigningUp(false);
  };

  const handleClickSignUp: MouseEventHandler = () => {
    setError(null);
    setIsSigningUp(true);
  };

  const handleInputEmail: FormEventHandler = (event) => {
    // TODO: hm...
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    setEmail((event as any).target.value);
  };

  const handleInputPassword: FormEventHandler = (event) => {
    // TODO: hm...
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    setPassword((event as any).target.value);
  };

  const handleSubmitForm = async (event: FormEvent): Promise<void> => {
    try {
      event.preventDefault();

      setError(null);

      setIsSubmitting(true);

      const body = {
        email,
        password,
      };

      const endpoint = isSigningUp ? '/api/signup' : '/api/login';

      const headers = new Headers({
        'Content-Type': 'application/json',
      });

      const response: Response = await fetch(endpoint, {
        body: JSON.stringify(body),
        headers,
        method: 'POST',
      });

      const responseBody: ApiResponse<{ token: string }> =
        await response.json();

      if ('token' in responseBody) {
        const { token } = responseBody;
        sessionStorage.setItem('token', token);
        setAuthState({ token });
        // Maybe else if is not needed.
      } else if ('error' in responseBody) {
        // eslint-disable-next-line @typescript-eslint/no-shadow
        const { error } = responseBody;
        throw new Error(error);
      }
      // eslint-disable-next-line @typescript-eslint/no-shadow
    } catch (error) {
      sessionStorage.setItem('token', '');
      setAuthState({ token: '' });

      if (error instanceof Error) {
        const { message } = error;
        setError(message);
      }
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <main className="container grid place-content-center place-items-center h-screen mx-auto">
      <h1 className="font-medium text-2xl">Welcome to Streamnote.</h1>
      <h2 className="text-lg">Note-taking for busy people.</h2>
      <form
        aria-busy={isSubmitting}
        aria-describedby={error !== null ? 'error' : undefined}
        className="flex flex-col items-center mt-4"
        // eslint-disable-next-line @typescript-eslint/no-misused-promises
        onSubmit={handleSubmitForm}
        title={isSigningUp ? 'Sign-up' : 'Sign-in'}
      >
        {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
        <label htmlFor="email">E-mail address:</label>
        <input
          aria-busy={isSubmitting}
          autoComplete="email"
          className="border-2 border-black mb-2 min-w-[14rem] px-3 py-1.5 rounded-lg text-lg"
          disabled={isSubmitting}
          id="email"
          onInput={handleInputEmail}
          placeholder="example@example.com"
          required
          type="email"
          value={email}
        />
        {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
        <label htmlFor="password">Password:</label>
        <input
          aria-busy={isSubmitting}
          autoComplete={isSigningUp ? 'new-password' : 'current-password'}
          className="border-2 border-black mb-4 min-w-[14rem] px-3 py-1.5 rounded-lg text-lg"
          disabled={isSubmitting}
          id="password"
          minLength={8}
          onInput={handleInputPassword}
          required
          type="password"
          value={password}
        />
        <button
          aria-busy={isSubmitting}
          disabled={isSubmitting}
          className={classnames(
            'border-2 border-black mb-2 min-w-[4.625rem] px-2 py-1 rounded-lg text-lg',
            {
              'border-slate-300': isSubmitting,
              'cursor-not-allowed': isSubmitting,
            },
          )}
          type="submit"
        >
          {/* eslint-disable-next-line no-nested-ternary */}
          {isSubmitting ? (
            <span
              aria-valuetext="Loading"
              className="animate-spin border-2 border-r-0 border-slate-300 h-5 inline-block rounded-full w-5"
              role="progressbar"
            />
          ) : isSigningUp ? (
            'Sign up'
          ) : (
            'Sign in'
          )}
        </button>
        {error !== null && (
          <div className="text-red-600" id="error" role="alert">
            {error}
          </div>
        )}
        <aside>
          {isSigningUp ? (
            <>
              Already have an account?{' '}
              <button
                className="underline"
                onClick={handleClickSignIn}
                type="button"
              >
                Sign in
              </button>
              .
            </>
          ) : (
            <>
              Need an account?{' '}
              <button
                className="underline"
                onClick={handleClickSignUp}
                type="button"
              >
                Sign up
              </button>
              .
            </>
          )}
        </aside>
      </form>
    </main>
  );
}

export default SignIn;
