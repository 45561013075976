import { atom } from 'recoil';

export const authState = atom<Nullable<AuthAtom>>({
  key: 'Auth',
  default: null,
});

export const editorState = atom<Nullable<EditorAtom>>({
  key: 'Editor',
  default: null,
});
