import React from 'react';
import ReactDOM from 'react-dom/client';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import { RecoilRoot } from 'recoil';

import Editor from './components/editor';
import SignIn from './components/sign-in';

import './index.css';

const router = createBrowserRouter([
  {
    element: <Editor />,
    path: '/',
  },
  {
    element: <SignIn />,
    path: '/sign-in',
  },
  {
    element: 'Coming soon...',
    path: '/sign-up',
  },
]);

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLBodyElement,
);

root.render(
  <RecoilRoot>
    <RouterProvider router={router} />
  </RecoilRoot>,
);
