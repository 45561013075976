import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useRecoilState } from 'recoil';

import { useIsLoggedIn } from '../../hooks';
import { authState, editorState } from '../../state';

import Banner from '../banner';
import DeletedList from '../deleted-list';
import Footer from '../footer';
import Input from '../input';
import TodoList from '../todo-list';

// eslint-disable-next-line @typescript-eslint/no-var-requires
const { debounce } = require('lodash');

function Editor(): JSX.Element {
  const navigate = useNavigate();

  const [authStateValue] = useRecoilState(authState);
  const [editorStateValue, setEditorState] = useRecoilState(editorState);

  const [items, setItems] = useState<Nullable<Item[]>>(null);
  const [textboxValue, setTextboxValue] = useState<string>('');

  useEffect(() => {
    if (typeof authStateValue?.token !== 'string' || items !== null) {
      return;
    }

    (async () => {
      try {
        const headers = new Headers({
          Authorization: `Bearer ${authStateValue?.token ?? ''}`,
        });

        const response: Response = await fetch('/api/items', {
          headers,
        });

        const responseBody: ApiResponse<Item[]> = await response.json();

        if (Array.isArray(responseBody)) {
          setItems(responseBody);
          // Maybe else if is not needed.
        } else if ('error' in responseBody) {
          const { error } = responseBody;
          throw new Error(error);
        }
      } catch (error) {
        // TODO: error handling.
      }
    })();
  }, [authStateValue, items]);

  useIsLoggedIn(
    (): void => {},
    (): void => {
      navigate('/sign-in');
    },
  );

  const debouncedSaveItems = useCallback(
    // eslint-disable-next-line @typescript-eslint/no-shadow
    debounce(async (items: Item[]) => {
      try {
        const token = sessionStorage.getItem('token');

        const headers = new Headers({
          Authorization: `Bearer ${token ?? ''}`,
          'Content-Type': 'application/json',
        });

        const response: Response = await fetch('/api/items', {
          body: JSON.stringify(items),
          headers,
          method: 'PUT',
        });

        const responseBody: ApiResponse<Item[]> = await response.json();

        if (Array.isArray(responseBody)) {
          setEditorState((currentEditorState) => {
            if (currentEditorState !== null) {
              return {
                ...currentEditorState,
                dirty: false,
              };
            }

            return currentEditorState;
          });

          setItems(responseBody);
          // Maybe else if is not needed.
        } else if ('error' in responseBody) {
          const { error } = responseBody;
          throw new Error(error);
        }
      } catch (error) {
        // TODO: error handling.
      }
    }, 200),
    // We don’t want this function to ever change:
    // `items` is passed as an argument and the useEffect() reacts to changes in `items`.
    [],
  );

  useEffect(
    () => {
      if ((editorStateValue?.dirty ?? false) && items !== null) {
        debouncedSaveItems(items);
      }
    },
    // Can’t do items.toString() because `items` might be null.
    [debouncedSaveItems, editorStateValue?.dirty, JSON.stringify(items)],
  );

  useEffect(
    () => {
      setEditorState({
        dirty: false,
        items,
        setItems,
        setTextboxValue,
        textboxValue,
      });
    },
    // Per (legacy?) React docs, the setXYZ() functions are stable.
    // No need for these to be included.
    [JSON.stringify(items), textboxValue],
  );

  return (
    <div className="container flex flex-col h-screen max-md:px-6 max-w-2xl mx-auto">
      <Banner />
      <main>
        <Input />
        <TodoList />
        <DeletedList />
      </main>
      <Footer />
    </div>
  );
}

export default Editor;
